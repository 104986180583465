import { useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
	AppBar, Container, Toolbar, Box, IconButton,
	Menu, MenuItem, Button, Badge, Tooltip
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {Home, User, Users, MessageCircle, Crown, Menu as MenuIcon, Bell, Heart, Compass, UserRound} from 'lucide-react'
import Logo from '../../images/logo.png'
import UserMenu from './UserMenu'
import NotificationMenu from './NotificationMenu'
import { addUserNotification } from "../../reducers/userNotificationsReducer"

const navbar_theme = createTheme({
	palette: {
		primary: {
			main: '#fff',
		},
		secondary: {
			main: '#A928E9',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					fontSize: '0.95rem',
					transition: 'all 0.2s ease-in-out',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					transition: 'all 0.2s ease-in-out',
				},
			},
		},
	},
})

const NavBar = ({ socket }) => {
	const [anchorElNav, setAnchorElNav] = useState(null)
	const [anchorElNotif, setAnchorElNotif] = useState(null)

	const user = useSelector(state => state.user)
	const profileData = useSelector(state => state.profile)
	const allNotifications = useSelector(state => state.userNotifications)
	const dispatch = useDispatch()

	const unreadMessageCount = useMemo(() => {
		return allNotifications.filter(notification =>
			notification.read === 'NO' &&
			notification.text.includes('You received a new message')
		).length
	}, [allNotifications])

	useEffect(() => {
		const handleNewNotification = (data) => {
			dispatch(addUserNotification(data))
		}

		if (socket) {
			socket.on('new_notification', handleNewNotification)
			socket.on('new_message', handleNewNotification)

			return () => {
				socket.off('new_notification', handleNewNotification)
				socket.off('new_message', handleNewNotification)
			}
		}
	}, [socket, dispatch])

	const pages =
		// user ?
			{
		'Browsing': { path: '/browsing', icon: Compass, tooltip: 'Browse Users' },
		'Profile': { path: '/profile', icon: UserRound, tooltip: 'View Profile' },
		'Chat': { path: '/chat', icon: MessageCircle, tooltip: 'Messages' },
		// 'Pricing': { path: '/pricing', icon: Crown, tooltip: 'Premium Plans' }
	// } : {
	// 	'Login': { path: '/login', icon: User, tooltip: 'Sign In' },
	// 	'Sign up': { path: '/signup', icon: Heart, tooltip: 'Create Account' }
	}

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget)
	}

	const handleCloseNavMenu = () => {
		setAnchorElNav(null)
	}

	const handleOpenNotifMenu = (event) => {
		setAnchorElNotif(event.currentTarget)
	}

	const handleCloseNotifMenu = () => {
		setAnchorElNotif(null)
	}

	const NavMenuItem = ({ page, path, Icon, tooltip }) => {
		const buttonContent = (
			<>
				{Icon && (
					<Icon
						className="w-[18px] h-[18px] mr-2 group-hover:stroke-purple-600 transition-all duration-200 text-gray-700"
						style={{ strokeWidth: 1.5 }}
					/>
				)}
				<span className="group-hover:text-purple-600 transition-all duration-200 text-gray-700">{page}</span>
				{page === 'Chat' && unreadMessageCount > 0 && (
					<Box
						sx={{
							ml: 1,
							backgroundColor: '#FF3B6B',
							color: 'white',
							borderRadius: '12px',
							fontSize: '0.7rem',
							fontWeight: '600',
							px: 1.5,
							py: 0.5,
							minWidth: '24px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							boxShadow: '0 2px 5px rgba(255, 59, 107, 0.25)',
						}}
					>
						{unreadMessageCount}
					</Box>
				)}
			</>
		)

		return (
			<Tooltip title={tooltip} arrow>
				<Button
					color="inherit"
					onClick={handleCloseNavMenu}
					component={Link}
					to={path}
					className="group"
					sx={{
						mr: 1,
						px: 2,
						py: 1.5,
						borderRadius: '12px',
						display: 'flex',
						alignItems: 'center',
						'&:hover': {
							bgcolor: 'rgba(169, 40, 233, 0.04)',
							transform: 'translateY(-1px)',
						},
						'&:active': {
							transform: 'translateY(0)',
						},
					}}
				>
					{buttonContent}
				</Button>
			</Tooltip>
		)
	}

	return (
		<ThemeProvider theme={navbar_theme}>
			<AppBar
				elevation={0}
				sx={{
					backgroundColor: 'rgba(255, 255, 255, 0.9)',
					backdropFilter: 'blur(10px)',
					borderBottom: '1px solid rgba(0,0,0,0.05)',
				}}
			>
				<Container maxWidth="100%">
					<Toolbar disableGutters sx={{ minHeight: '70px' }}>
						<Box
							component={Link}
							to="/"
							sx={{
								display: { xs: 'none', md: 'flex' },
								marginRight: 3,
								height: '40px',
								'&:hover': {
									opacity: 0.85,
									transform: 'scale(1.02)',
								},
								transition: 'all 0.2s ease-in-out',
							}}
						>
							<img src={Logo} alt="logo" style={{ height: '100%' }} />
						</Box>

						{/* Mobile Menu */}
						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
							<IconButton
								onClick={handleOpenNavMenu}
								sx={{
									'&:hover': {
										bgcolor: 'rgba(169, 40, 233, 0.04)',
									},
								}}
							>
								<MenuIcon style={{ strokeWidth: 1.5 }} />
							</IconButton>
							<Menu
								anchorEl={anchorElNav}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
									'& .MuiPaper-root': {
										borderRadius: '16px',
										mt: 1,
										boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
									},
								}}
							>
								{Object.entries(pages).map(([page, { path, icon: Icon }]) => (
									<MenuItem
										key={page}
										onClick={handleCloseNavMenu}
										component={Link}
										to={path}
										sx={{
											borderRadius: '8px',
											mx: 1,
											my: 0.5,
											'&:hover': {
												bgcolor: 'rgba(169, 40, 233, 0.04)',
											},
										}}
									>
										<Icon
											className="w-3 h-3 mr-2 "
											style={{ strokeWidth: 1 }}
										/>
										{page}
										{page === 'Chat' && unreadMessageCount > 0 && (
											<Box
												sx={{
													ml: 1,
													backgroundColor: '#FF3B6B',
													color: 'white',
													borderRadius: '12px',
													fontSize: '0.7rem',
													fontWeight: '600',
													px: 1.5,
													py: 0.5,
													minWidth: '24px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												{unreadMessageCount}
											</Box>
										)}
									</MenuItem>
								))}
							</Menu>
						</Box>

						{/* Mobile Logo */}
						<Box
							sx={{
								flexGrow: 1,
								height: '40px',
								display: { xs: 'flex', md: 'none' },
								justifyContent: 'center',
							}}
							component={Link}
							to="/"
						>
							<img src={Logo} alt="logo" style={{ height: '100%' }} />
						</Box>

						{/* Desktop Navigation */}
						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
							{Object.entries(pages).map(([page, { path, icon: Icon, tooltip }]) => (
								<NavMenuItem key={page} page={page} path={path} Icon={Icon} tooltip={tooltip} />
							))}
						</Box>

						{/* Subscription Status */}
						{user && profileData && profileData?.subscription?.days_left > 0 && (
							<Tooltip title="Premium Subscription Status" arrow>
								<Box
									sx={{
										px: 2,
										py: 1,
										borderRadius: '12px',
										background: 'linear-gradient(135deg, rgba(169, 40, 233, 0.1) 0%, rgba(169, 40, 233, 0.05) 100%)',
										border: '1px solid rgba(169, 40, 233, 0.2)',
										display: 'flex',
										alignItems: 'center',
										gap: 1,
										mr: 2,
										transition: 'all 0.2s ease-in-out',
										'&:hover': {
											transform: 'translateY(-1px)',
											boxShadow: '0 2px 8px rgba(169, 40, 233, 0.15)',
										},
									}}
								>
									<Crown
										className="w-4 h-4 text-purple-600"
										style={{ strokeWidth: 1.5 }}
									/>
									<Box sx={{ fontSize: '0.85rem', color: 'rgb(144, 92, 244)' }}>
										<span style={{ fontWeight: 600 }}>{profileData?.plans?.plan_name}</span>
										<span style={{ marginLeft: '4px' }}>
                      {profileData?.subscription?.days_left}d
                    </span>
									</Box>
								</Box>
							</Tooltip>
						)}

						{/* Notifications */}

						<Menu
							anchorEl={anchorElNotif}
							open={Boolean(anchorElNotif)}
							onClose={handleCloseNotifMenu}
							sx={{
								'& .MuiPaper-root': {
									borderRadius: '16px',
									mt: 2,
									boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
									minWidth: 320,
								},
							}}
						>
							<NotificationMenu onClose={handleCloseNotifMenu} />
						</Menu>

						{/* User Menu */}
						<UserMenu user={user} socket={socket} />
					</Toolbar>
				</Container>
			</AppBar>
		</ThemeProvider>
	)
}

export default NavBar
